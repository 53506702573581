import { DashboardGaugeIcon } from "@panwds/icons";
import { MAIN_ROUTE_AI_FW } from "./lib/constants";
import { getFrameworkVars } from "@sparky/framework";

const ASSOCIATED_APPS = [
    "strata_insights_free",
    "strata_insights",
    "prisma_access",
    "prisma_access_onprem",
    "prisma_access_panorama",
    "cgx",
];

// function hideDashboards(auth: { instances?: { app_id?: string }[] }) {
//     // disable dashboards for enterprise dlp or ng_casb only tenants
//     // load dashboards tab only if associated apps are present
//     // at present these are the apps which contribute to dashboards
//     const instances = auth?.instances;
//     const instance_apps = instances?.map((instance) => instance.app_id) || [];
//     const found = ASSOCIATED_APPS.some((r) => instance_apps.indexOf(r) >= 0);
//     if (!found) {
//         return true;
//     }
//     return false;
// }

function isAvailable(obj: unknown) {
    // //do not display dashboards in China Region
    // if (getAuthState()?.instances?.getPlatformRegion() === "cn") {
    //     return false;
    // }
    // if (getFrameworkVars()?.private_site_origin) {
    //     return true;
    // } // GCS
    // if (hideDashboards(obj.auth)) {
    //     return false;
    // }
    // if (!obj || !obj.access) {
    //     return false;
    // }
    // if (obj.access.hasRole("ademsupport")) {
    //     return false;
    // }
    // // if (access.hasRole("adem_tier_1_support")) return false

    // const isSuperUser = obj.access
    //     .filter(
    //         ({ app_id }: { app_id: string }) =>
    //             app_id === "logging_service" || app_id === "" || ASSOCIATED_APPS.includes(app_id),
    //     )
    //     .map((r: { roleNames?: unknown }) => r.roleNames)
    //     .some((appRoleSet: string[]) =>
    //         appRoleSet.some((r: string) => ["superuser", "super user", "superadmin", "super admin"].includes(r)),
    //     );

    // if (isSuperUser) {
    //     return true;
    // }

    // const isTsgBusinessAdmin = obj.access
    //     .filter(({ app_id }: { app_id: string }) => app_id === "logging_service" || app_id === "")
    //     .map((r: { roleNames?: unknown }) => r.roleNames)
    //     .every((appRoleSet: string[]) =>
    //         appRoleSet.some((r: string) => ["business_admin", "iam_admin", "msp_iam_admin"].includes(r)),
    //     );

    // if (isTsgBusinessAdmin) {
    //     return false;
    // }

    return true;
}

const { NODE_ENV } = process.env;
const env = NODE_ENV || "development";
const isDev = env === "development";

export default async () => {
    return async () => {
        const { user_preferences_ui, pdf_mode } = getFrameworkVars();
        const isUserPreferencesSupported = Boolean(user_preferences_ui);
        const isPDF = Boolean(pdf_mode);

        return {
            navigation: [
                {
                    key: "ai_fw",
                    title: "AI FW",
                    icon: DashboardGaugeIcon,
                    className: "parent-node",
                    contentClassName: "panwds-tw3" + (isUserPreferencesSupported ? "" : " sparky-dark-only"),
                    path: MAIN_ROUTE_AI_FW,
                    mergeNav: true,
                    expandable: false,
                    hidden: !isDev,
                    callAvail: true,
                    avail: isAvailable,
                    component: () => import("./components/core/Root"),
                },
            ],
        };
    };
};
